<template>
  <v-row class="pa-3">
    <v-col cols="12">
      <v-card class="pb-2">
        <v-card-title>
          <label class="font-weight-bold grey--text text--lighten-1 body-2"
            >RIWAYAT SURAT</label
          >
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red"
            v-if="filter_open"
            class="mx-0 white--text"
            tile
            @click="reset()"
            >RESET</v-btn
          >
          <v-btn
            depressed
            color="#F4F5F5"
            @click="filterOpen()"
            tile
            class="mx-3"
          >
            <img src="@/assets/image/ic_filter.png" class="mr-2" />
            <span class="black--text text-capitalize">Filters</span>
          </v-btn>
          <v-text-field
            placeholder="Cari dokter atau klinik disini..."
            solo
            flat
            prepend-inner-icon="mdi-magnify"
            hide-details
            class="search"
            v-model="search"
          />
        </v-card-title>
        <v-row v-if="filter_open" class="pt-2 pb-3 px-3">
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Range Tanggal</label>
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="datepicker"
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="date.formatted"
                  v-on="on"
                  readonly
                  placeholder="Tanggal"
                  outlined
                  dense
                  hide-details="false"
                  single-line
                ></v-text-field>
              </template>
              <v-date-picker
                multiple
                v-model="selectedDate"
                @input="changedate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col align-self="center" class="pt-8 px-0" style="max-width: 30px">
            <v-btn icon class="ma-0" @click="selectSort()">
              <v-icon>mdi-swap-vertical</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Jenis Surat</label>
            <v-combobox
              placeholder="Semua Jenis Surat"
              outlined
              dense
              hide-details
              single-line
              :items="resource.letterType"
              item-text="text"
              v-model="filterLetter"
              @change="getLetter('')"
              clearable
            />
          </v-col>
          <v-col class="text-left">
            <label class="font-weight-medium body-2">Nama Unit</label>
            <v-combobox
              placeholder="Semua Unit"
              outlined
              dense
              hide-details
              single-line
              :items="clinic"
              item-text="name"
              v-model="unitFilter"
              @change="getLetter('')"
              clearable
            />
          </v-col>
        </v-row>

        <!-- <v-row :key="'title-' + index" align="center" class="mx-3" no-gutters>
            <label class="text-uppercase grey--text font-weight-bold">{{
              letter.type
            }}</label>
          </v-row> -->
        <div
          v-for="(letter, idx) in letters.filter(i => i.item.length > 0)"
          :key="`item-${idx}`"
        >
          <v-card
            v-for="(item, indexing) in letter.item"
            flat
            class="pa-3 ma-3 text-left"
            style="border: 0.5px solid #429ddb; border-radius: 5px"
            :key="'item-' + indexing"
          >
            <v-row no-gutters>
              <v-col cols="3" align-self="center">
                <h3 class="text-left">{{ item.description }}</h3>
                <v-chip
                  color="#B279EB"
                  outlined
                  label
                  v-if="item.description === 'Surat Keterangan Rujukan'"
                  class="font-weight-medium mt-2"
                  >Rujuk Lanjut
                  {{ item.isBack ? ' - Rujuk Balik' : '' }}</v-chip
                >
                <div
                  v-if="
                    ![
                      'Surat Kontrol Rawat Jalan',
                      'Surat Perintah Rawat Inap',
                      'Surat Keterangan Lahir',
                    ].includes(item.description)
                  "
                >
                  <v-btn
                    class="text-capitalize mx-0 caption px-1"
                    outline
                    depressed
                    color="#9B51E0"
                    style="background-color: #9b51e0"
                    v-if="item.description === 'Surat Rujukan Rumah Sakit Lain'"
                  >
                    {{ item.category }}
                  </v-btn>
                  <!-- <v-btn
                    class="text-capitalize mx-0 caption px-1"
                    outline
                    depressed
                    color="#E5A82E"
                    style="background-color: #fff9e5"
                    v-if="item.description === 'Surat Keterangan Dokter'"
                  >
                    {{ item.category }}
                  </v-btn> -->
                  <v-btn
                    class="text-capitalize mx-0 caption px-1"
                    outline
                    depressed
                    color="#EB4747"
                    style="background-color: #fff2f2; display: none"
                    v-if="item.description === 'Surat Keterangan Kematian'"
                  >
                    {{ item.category }}
                  </v-btn>
                  <v-btn
                    class="text-capitalize mx-0 caption px-1"
                    outline
                    depressed
                    color="#EB4747"
                    style="background-color: #fff2f2"
                    v-if="item.description === 'Surat Rujuk Internal'"
                  >
                    {{ item.origin.referral_unit_name }}
                  </v-btn>
                </div>
              </v-col>
              <v-col align-self="center" class="text-left" cols="2">
                <div style="display: grid">
                  <label class="caption grey--text">Nama Unit :</label>
                  <label class="font-weight-medium caption">{{
                    item.unit
                  }}</label>
                </div>
              </v-col>
              <v-col align-self="center" class="text-left">
                <div style="display: grid">
                  <label class="caption grey--text"
                    >Tanggal Pembuatan Surat :</label
                  >
                  <label class="font-weight-medium caption">{{
                    item.dateSplit[0]
                  }}</label>
                </div>
              </v-col>
              <v-col align-self="center" class="text-left">
                <div style="display: grid">
                  <label class="caption grey--text">Dibuat Oleh :</label>
                  <label class="font-weight-medium caption">{{
                    item.doctor
                  }}</label>
                </div>
              </v-col>
              <v-col align-self="center" cols="2" class="text-right">
                <v-tooltip bottom>
                  <v-btn
                    outlined
                    class="btn-icon mx-2"
                    slot="activator"
                    @click="printLetter(indexing, letter, letter.type, true)"
                  >
                    <!-- @click="printLetter(letter.category, letter, true)" -->
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <span>Download</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn
                    @click="printLetter(indexing, letter, letter.type, false)"
                    outlined
                    class="btn-icon"
                    slot="activator"
                  >
                    <!-- @click="printLetter(letter.category, letter, false)" -->
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                  <span>Print</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div v-if="resource.letters.every(letter => letter.item.length === 0)">
          <v-alert :value="true" type="info" class="white--text mx-3">
            Maaf data riwayat surat pasien tidak ditemukan
          </v-alert>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import logoFix1 from '@/assets/image/logoFix.js';
import Constant from '@/const';
import moment from 'moment-timezone';
import printMixin from '@/mixin/printMixin';
import Letter from '@/mixin/Letter';
import 'jspdf-autotable';
import alertMixin from '../../../mixin/alertMixin';

const _ = require('lodash');

const axios = require('axios');
// const _ = require('lodash');

export default {
  name: 'LetterComponent',
  mixins: [printMixin, alertMixin, Letter],
  props: {
    id_emr: {
      type: String,
    },
    clinic: {
      type: Array,
    },
  },
  data() {
    return {
      filter_open: false,
      startDate: '',
      clientData: this.$store.getters.userLoggedIn.clinic,
      endDate: '',
      filterLetter: '',
      unitFilter: '',
      letters: [],
      dataLetter: '',
      resource: {
        letterType: [
          { text: 'Surat Kontrol Rawat Jalan', value: 'outpatient' },
          { text: 'Surat Perintah Rawat Inap', value: 'inpatient' },
          { text: 'Surat Keterangan Kematian', value: 'death' },
          { text: 'Surat Keterangan Sakit', value: 'sick' },
          { text: 'Surat Keterangan Dokter', value: 'health' },
          {
            text: 'Surat Rujukan ke Rumah Sakit',
            value: 'referral',
          },
        ],
        unit: [],
        letters: [],
      },
      selectedDate: [],
      date: {
        raw: [],
        formatted: moment().format('DD-MM-YYYY'),
      },
      datePicker: false,
      search: '',
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  mounted() {
    this.getLetter('');
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getLetter();
    }, 650),
    filterOpen() {
      this.filter_open = !this.filter_open;
    },
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.date = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.datepicker = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.date = {
          raw: [data[0], ''],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
      this.getLetter();
    },
    reset() {
      this.date.raw = [];
      this.date.formatted = moment().format('DD-MM-YYYY');
      this.unitFilter = null;
      this.filterLetter = null;
      this.getLetter();
    },
    printLetter(index, data, type, isDownload) {
      switch (type) {
        case 'inpatient':
          this.printInpatientLetter(index, data, isDownload);
          break;
        case 'outpatient':
          this.printOutpatientLetter(index, data, isDownload);
          break;
        case 'health':
          this.printHealthLetter(index, data, isDownload);
          break;
        case 'sick':
          this.printSickLetter(index, data, isDownload);
          break;
        case 'death':
          this.printDeathLetter(index, data, isDownload);
          break;
        case 'referral':
          this.printReferralLetter(index, data, isDownload);
          break;
      }
    },
    printReferralLetter(index, data, isDownload) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/referral-patient-letter/registration/${data.item[index].id_registration}`,
          ),
        )
        .then(response => {
          const { data: letterData } = response.data;
          const doc = data.item[index].isBpjs
            ? this.generateReferralLetterBpjs(
                letterData,
                data.item[index].isBack,
              )
            : this.generateReferralLetterNonBpjs(letterData);

          if (isDownload) {
            doc.save(
              `${
                data.item[index].isBpjs
                  ? data.item[index].isBack
                    ? 'Surat Rujuk Lanjut - Rujuk Balik'
                    : 'Surat Rujuk Lanjut'
                  : 'Surat Rujukan Umum Asuransi'
              }_${letterData.medical_record_number}_${moment().format(
                'DD-MM-YYYY',
              )}.pdf`,
            );
          } else {
            this.printDocument(doc, {
              docName: data.item[index].isBpjs
                ? data.item[index].isBack
                  ? 'Surat Rujuk Lanjut - Rujuk Balik'
                  : 'Surat Rujuk Lanjut'
                : 'Surat Rujukan Umum Asuransi',
              rmNumber: letterData.medical_record_number,
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    printDeathLetter(index, dataProps, isDownload) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/death-patient-letter/${dataProps.item[index]._id}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          const patientData = {
            rmNumber: data.patient.medical_record_number,
            numberLetter: data.letter_number,
            meta: {
              name: data.patient.name,
              doctor: data.detail.doctor,
              role: data.detail.role,
              age: data.patient.age,
              gender: data.patient.gender,
              birthDate: data.patient.birth,
              address: data.patient.address,
              identityNumber: data.patient.identity_number,
            },
          };
          const input = {
            disease_type: data.detail.disease_type,
            day: data.detail.day,
            date: data.detail.date,
            time: data.detail.time,
            doctor: {
              doctorName: data.detail.doctor,
              sip: data.detail.sip,
            },
          };

          const doc = dataProps.item[index].isDoa
            ? this.generateDOALetter(patientData, input)
            : this.generateDeathLetter(patientData, input);

          if (isDownload) {
            doc.save(
              `Surat Keterangan Kematian_${
                patientData.rmNumber
              }_${moment().format('DD-MM-YYYY')}.pdf`,
            );
          } else {
            this.printDocument(doc, {
              docName: 'Surat Keterangan Kematian',
              rmNumber: patientData.rmNumber,
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    printSickLetter(index, data, isDownload) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/sick-patient-letter/${data.item[index]._id}`,
          ),
        )
        .then(response => {
          const data = response.data.data;

          const patientData = {
            rmNumber: data.id_emr,
            numberLetter: data.letter_number,
            meta: {
              name: data.patient.name,
              gender: data.patient.gender,
              age: data.patient.age,
              address: data.patient.address,
              profession: data.patient.profession,
            },
          };
          const input = {
            date_start: data.detail.date_start,
            date_end: data.detail.date_end,
            doctor: {
              doctorName: data.detail.doctor,
              sip: data.detail.sip,
            },
          };

          const doc = this.generateSickLetter(patientData, input);

          if (isDownload) {
            doc.save(
              `Surat Keterangan Sakit_${patientData.rmNumber}_${moment().format(
                'DD-MM-YYYY',
              )}.pdf`,
            );
          } else {
            this.printDocument(doc, {
              docName: 'Surat Keterangan Sakit',
              rmNumber: patientData.rmNumber,
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    printHealthLetter(index, data, isDownload) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/healthy-patient-letter/${data.item[index]._id}`,
          ),
        )
        .then(response => {
          const data = response.data.data;

          const patientData = {
            rmNumber: data.patient.medical_record_number,
            numberLetter: data.letter_number,
            meta: {
              name: data.patient.name,
              gender: data.patient.gender,
              age: data.patient.age,
              address: data.patient.address,
              profession: data.patient.profession,
            },
          };
          const input = {
            height: data.detail.height,
            weight: data.detail.weight,
            sistole: data.detail.sistole,
            diastole: data.detail.diastole,
            pulse: data.detail.pulse,
            respiration: data.detail.respiration,
            patient_condition: data.detail.patient_condition,
            purpose_letter: data.detail.purpose_letter,
            doctor: {
              doctorName: data.detail.doctor,
              sip: data.detail.sip,
            },
          };

          const doc = this.generateHealthLetter(patientData, input);

          if (isDownload) {
            doc.save(
              `Surat Keterangan Dokter_${
                patientData.rmNumber
              }_${moment().format('DD-MM-YYYY')}.pdf`,
            );
          } else {
            this.printDocument(doc, {
              docName: 'Surat Keterangan Dokter',
              rmNumber: patientData.rmNumber,
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    printInpatientLetter(index, data, isDownload) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/control-inpatient-letter/${data.item[index]._id}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          const patientData = {
            rmNumber: data.patient.medical_record_number,
            numberLetter: data.letter_number,
            meta: {
              name: data.patient.name,
              birthDate: data.patient.birth,
              address: data.patient.address,
            },
          };
          const input = {
            dpjp: data.detail.dpjp,
            sip: data.detail.sip,
            diagnosis_in: data.detail.diagnosis_in.map(item => ({
              text: item,
            })),
            insurance_type: data.detail.insurance_type,
            case_type: data.detail.case_type,
            service_type: data.detail.service_type,
          };
          const doc = this.generateInpatientLetter(patientData, input);

          if (isDownload) {
            doc.save(
              `Surat Perintah Rawat Inap_${
                patientData.rmNumber
              }_${moment().format('DD-MM-YYYY')}.pdf`,
            );
          } else {
            this.printDocument(doc, {
              docName: 'Surat Perintah Rawat Inap',
              rmNumber: patientData.rmNumber,
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    printOutpatientLetter(index, data, isDownload) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/out-patient-control-letter/${data.item[index]._id}`,
          ),
        )
        .then(response => {
          const dataLetter = response.data.data;

          const patientData = {
            rmNumber: dataLetter.medical_record_number,
            meta: {
              name: dataLetter.patient.name,
              birthDate: dataLetter.patient.birth.date,
              address: dataLetter.patient.address,
              polyName: dataLetter.unit.name,
            },
          };
          const input = {
            diagnose: dataLetter.detail.diagnose.map(item => ({
              text: item.name,
            })),
            therapies: dataLetter.detail.therapies,
            suggestions: dataLetter.detail.suggestions,
            numberLetter: dataLetter.letter_number,
            returnStatus: dataLetter.detail.return_type,
            doctor: {
              doctorName: dataLetter.detail.doctor,
              sip: dataLetter.detail.sip,
            },
            controlDate: dataLetter.detail.return_control_date,
          };
          const doc = this.generateOutpatientLetter(patientData, input);

          if (isDownload) {
            doc.save(
              `Surat Kontrol Rawat Jalan_${
                patientData.rmNumber
              }_${moment().format('DD-MM-YYYY')}.pdf`,
            );
          } else {
            this.printDocument(doc, {
              docName: 'Surat Kontrol Rawat Jalan',
              rmNumber: patientData.rmNumber,
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        })
        .finally((this.dataLetter = ''));
    },
    // selectSort() {
    //   this.resource.letter.reverse();
    // },
    getLetter() {
      this.resource.letter = [];
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/letter/${this.id_emr}?startDate=${this.date.raw[0] ||
              ''}&endDate=${this.date.raw[1] || ''}&unit=${
              this.unitFilter ? this.unitFilter.name : ''
            }&type=${
              this.filterLetter ? this.filterLetter.value : ''
            }&doctor=${this.search || ''}`,
          ),
        )
        .then(response => {
          const { data } = response.data;

          this.letters = data;
          this.resource.letters = data;
          // debugger;

          //  const inpatient = {
          //     type: 'Surat Perintah Rawat Inap',
          //     detail: data.inpatientLetter.letter.map(arr => {
          //       return {
          //         letterType: 'Surat Perintah Rawat Inap',
          //         unit: arr.unit[0].name,
          //         createdDate: moment(arr.detail[0].timestamps.created_at)
          //           .locale('id')
          //           .format('DD MMMM YYYY [pada] hh:mm [WIB]'),
          //         createdBy: arr.created_by[0].detail[0].name,
          //       };
          //     }),
          //   };

          //   const outpatient = {
          //     type: 'Surat Kontrol Rawat Jalan',
          //     detail: data.outpatientLetter.letter.map(arr => {
          //       return {
          //         letterType: 'Surat Kontrol Rawat Jalan',
          //         unit: arr.unit[0].name,
          //         createdDate: moment(arr.detail[0].timestamps.created_at)
          //           .locale('id')
          //           .format('DD MMMM YYYY [pada] hh:mm [WIB]'),
          //         createdBy: arr.created_by[0].detail[0].name,
          //       };
          //     }),
          //   };

          //   const healthy = {
          //     type: 'Surat Keterangan Sehat',
          //     detail: data.healthyLetter.letter.map(arr => {
          //       return {
          //         letterType: 'Surat Keterangan Sehat',
          //         unit: arr.unit[0].name,
          //         createdDate: moment(arr.detail[0].timestamps.created_at)
          //           .locale('id')
          //           .format('DD MMMM YYYY [pada] hh:mm [WIB]'),
          //         createdBy: arr.created_by[0].detail[0].name,
          //       };
          //     }),
          //   };

          //   const datas = [];
          //   datas.push(inpatient, outpatient, healthy);

          //   this.resource.letter = datas.filter(x => x.detail.length !== 0);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  border: 1px solid #9ca2a5;
  border-radius: 3px;
  width: 150px;
}

.btn-icon {
  border-color: #cdd2d7;
  height: 40px;
  min-width: 40px;
}
::v-deep .v-input__slot {
  min-height: 0px !important;
}
::v-deep .v-text-field.v-text-field--enclosed .v-input__append-inner,
.v-text-field.v-text-field--enclosed .v-input__append-outer,
.v-text-field.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field.v-text-field--enclosed .v-input__prepend-outer {
  margin-top: 5px;
}
</style>
